import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Form from '../components/Form';
import Link from 'gatsby';
import '../styles/layout.css';
import audrey from '../../static/audrey-wide.png';
import simultaantolken from '../../static/simultaantolken.jpg';
import verbindingstolken from '../../static/verbindingstolken.jpg';
import badge from '../../static/kudo-interpreter-badge.png';
import stempel from '../../static/stempel.jpg';
import sdl from '../../static/SDL.png';

export default () => (
  <div>
    <Helmet>
      <title>B&D – Audrey Fromont</title>
      <meta
        name="description"
        content="Beëdigd vertaler, beëdigd tolk, conferentietolk."
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="RSI, Kudo, Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua Qua
vertaler + tandheelkunde, SDL Trados, Translator + dentistry, dental translator, Dutch"
      />
    </Helmet>
    <Layout>
      <div className="columns">
        <div className="column is-full">
          <img src={audrey} />
        </div>
      </div>
      <div className="container is-fluid">
        <hr />
        <nav className="level">
          <h3
            style={{ fontFamily: 'Dancing Script' }}
            className="introItem level-item has-text-centered is-size-6-mobile"
          >
            BEËDIGD VERTALER - BEËDIGD TOLK - CONFERENTIETOLK
          </h3>
        </nav>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">SIMULTAANTOLKEN</h3>
            <p className="text-normal">
              <u>Gebruikte RSI-platformen (Remote Simultaneous Interpreting)</u>
            </p>
            <img className="mb-3" src={badge} alt="" width="100" />
            <p>
              … en even bedreven met{' '}
              <strong className="text-white">
                Interprefy, Interactio, Voiceboxer, Hopin, Zoom, Qua Qua
              </strong>
              , …
            </p>
            <p className="is-size-7-mobile">
              Simultaanvertaling gebeurt mondeling met behulp van het nodige
              materiaal, zoals microfoons, koptelefoons en eventueel een cabine.
              Zo kan een gesprek gelijktijdig gevolgd worden in de
              oorspronkelijke taal en in vertaling. U kunt een beroep op me doen
              voor simultaanvertaling in het Nederlands en het Engels. Bijna 20
              jaar heb ik het vertrouwen van tolkbureaus in binnen- en
              buitenland.
            </p>
            <img src={simultaantolken} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">VERBINDINGSTOLKEN</h3>
            <p className="is-size-7-mobile">
              Voor deze vorm van tolken is geen technisch materiaal vereist. Als
              <strong className="text-white"> (beëdigd) tolk</strong> vertaal ik
              mondeling bij rijexamens alsook bij verhoren door politie, sociale
              inspectie, dienst voogdij etc. U kunt in dit verband contact
              opnemen voor Nederlands, Frans, Engels, Spaans en Chinees
              (Mandarijns).
            </p>
            <img src={verbindingstolken} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">
              BEËDIGDE VERTALINGEN (SCHRIFTELIJK)
            </h3>
            <p className="is-size-7-mobile">
              U werd gevraagd een beëdigde vertaling in te dienen? Via het
              contactformulier kunt u bij me terecht voor Nederlands, Engels,
              Frans en Spaans. Ik heb de eed afgelegd bij de rechtbanken van
              Antwerpen, Brussel, Mechelen, Leuven, Veurne en Gent. Bijgevolg
              kan mijn handtekening, indien nodig, in al deze steden
              gelegaliseerd worden. In het buitenland wordt soms een apostille
              gevraagd bij een beëdigde vertaling. Klik
              <a className="is-lowercase" href="http://diplomatie.belgium.be">
                {' '}
                hier{' '}
              </a>
              (link). voor gedetailleerde informatie.
            </p>
            <img src={stempel} />
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">ALGEMENE VERTALINGEN</h3>
            <p className="is-size-7-mobile">
              <strong className="text-white">
                Specialisatie tandheelkunde
              </strong>
              ; vertalingen van algemene, commerciële, administratieve,
              notariële, juridische, financiële aard; mode, design, toerisme.
            </p>
            <p className="is-size-7-mobile">
              Het maakt niet uit of u nu een lijvig document of slechts één
              paragraaf te vertalen heeft. Stel uw vraag via het
              contactformulier en u ontvangt zo snel als kan een tarief en
              leveringstermijn. Vertalingen naar het Nederlands (mijn
              moedertaal) worden helemaal door mezelf afgehandeld. Vertalingen
              naar andere talen worden nagelezen door mijn collega’s die Engels,
              Spaans of Frans als moedertaal hebben.
            </p>
            <img src={sdl} alt="" />
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">LIDMAATSCHAP</h3>
            <p className="is-size-7-mobile">
              Lextra Lingua - Beroepsvereniging beëdigd vertalers/tolken
            </p>
            <p className="is-size-7-mobile">
              Centre for Translation Studies (CETRA)
            </p>
            <p className="is-size-7-mobile">
              Belgian Association of Anglicists in Higher Education (BAAHE)
            </p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">DIPLOMA'S</h3>
            <p className="is-size-7-mobile">
              Master in het vertalen - Universiteit Antwerpen
            </p>
            <p className="is-size-7-mobile">
              Master in het tolken - Vlekho Brussel
            </p>
            <p className="is-size-7-mobile">
              Doctoraat in de toegepaste taalkunde: vertaalwetenschap -
              Katholieke Universiteit Leuven
            </p>
          </div>
        </div>
        <hr />
        <div className="columns">
          <div className="column">
            <h3 className="is-size-6-mobile">CONTACT</h3>
            <p className="is-size-7-mobile">Astene (Deinze)</p>
            <p className="is-size-7-mobile">audrey.fromont@gmail.com</p>
            <p className="is-size-7-mobile">+32(0)475 29 20 28</p>
          </div>
          <div className="column">
            <h3 className="is-size-6-mobile">CONTACTFORMULIER</h3>
            <Form
              firstLabel="Naam:"
              secondLabel="E-mailadres:"
              thirdLabel="Bericht:"
              btnLabel="Verstuur"
              firstOption="- particulier"
              secondOption="- bedrijf/associatie/agentschap"
              thirdOption="- politiedienst"
              fourthOption="- universiteitsmedewerker"
              selectLabel="U contacteert mij als:"
            />
          </div>
        </div>
      </div>
    </Layout>
  </div>
);
